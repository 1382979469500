import * as React from 'react';
import api from './ApiClient';
import moment from 'moment';
import Duration from './Duration';

import './ResultsTable.scss';

interface ResultsTableProps {	
	results: any,
	competitors: any,
	onCompetitorClick: (competitor: any) => void,
    showETA: boolean,
};


export default class ResultsTable extends React.Component<ResultsTableProps, any> {

	componentDidMount() {
    }

    componentWillReceiveProps(nextProps) {      
        if (nextProps.seriesClasses != null) {
            var seriesClasses = {};
            for (var i = 0; i < nextProps.seriesClasses.length; i++) {
                seriesClasses[nextProps.seriesClasses[i].id] = nextProps.seriesClasses[i];
            }

            this.setState({
                seriesClasses: seriesClasses
            });
        }
    }

	state = {
        tableClass: 'showLapTime',
        seriesClasses: {}
	};

	render() {
		return (
			<div className="resultTable">
				<div className="form-group">
					<select className={['form-control', 'lapDataSelector'].join(' ')} onChange={(event) => this.setState({ tableClass: event.target.value })} >
						<option value="showLapTime">Lap times</option>
						<option value="showPos">Position</option>
						<option value="showPosDiff">Position change</option>
						<option value="showBehindFirst">Gap to first competitor</option>
						<option value="showBehindNext">Gap to next competitor ahead</option>
						<option value="showLaptimeDiff">Laptime diff previous lap</option>
						<option value="showLaptimeDiffFromFirst">Laptime diff from first competitor</option>
						<option value="showLaptimeDiffFromNext">Laptime diff from next competitor</option>
					</select>
				</div>


				<div className="scrolling outer">
					<div className="inner">

						<table className={['table', 'table-striped', 'table-hover', 'table-condensed', 'header-fixed', this.state.tableClass].join(' ')}>
							<thead>
								<tr>
									<th>Pos</th>
									<th>Number</th>
									<th>Name</th>
									<th>Age</th>
                                    <th>Class</th>
									<th>Laps</th>
									{this.props.showETA && <th>ETA</th>}
									<th>Totaltime</th>
									
									{this.props.results.length != 0 && this.props.results[0].checkpoints.filter(c => !c.deleted).map((c, index) => {
										return (<th key={c.id + '_' + index} className="lapHeader">Lap {index + 1}</th>)
									})}
								
								</tr>
							</thead>
							<tbody>
								{this.props.results.map((result, index) => {									
									var lastCheckpoint = result.checkpoints[result.checkpoints.length - 1];
									var competitor = this.props.competitors[result.competitor.id];
									return (
										<tr key={result.competitor.id}>
											<td>#{index + 1}</td>
											<td>{competitor.number}</td>
											<th><button onClick={() => this.props.onCompetitorClick(result.competitor)}>{competitor.name}</button></th>
											<td>{competitor.age}</td>
											<td>{result.competitor.group}</td>
											<td>{result.checkpoints.filter(cp => !cp.deleted).length}</td>

											{this.props.showETA && <td>{moment(lastCheckpoint.timestamp).add(lastCheckpoint.laptime, 'ms').fromNow()}</td>}

											<td>
												{Duration(lastCheckpoint.totaltime).format()}                                                
											</td>

											{result.checkpoints.map((checkpoint, index) => {
												if(!checkpoint.deleted)
												return (<td key={index} className={['lapCell', (checkpoint.fastestLap == true ? 'fastestLap' : ''), (checkpoint.bestLap == true ? 'bestLap' : ''), 'pos' + checkpoint.pos].join(' ')} >
													
													
													{checkpoint.posDiff > 0 && <i className="fas fa-sort-up text-success"></i>}
													{checkpoint.posDiff < 0 && <i className="fas fa-sort-down text-danger"></i>}
													{(checkpoint.bestLap && !checkpoint.fastestLap) && <i className="far fa-star text-warning"></i>}
													{checkpoint.fastestLap && <i className="fas fa-star text-warning"></i>}
													
													
                                                    <span className="lapTime">{Duration(checkpoint.laptime).format()}</span>                                                    
													<span className="behindFirst">{checkpoint.behindFirst ? Duration(checkpoint.behindFirst).format() : '--'}</span>
													<span className="behindNext">{checkpoint.behindNext ? Duration(checkpoint.behindNext).format() : '--'}</span>
													<span className="pos">{checkpoint.pos}</span>
													<span className="posDiff">{checkpoint.posDiff ? checkpoint.posDiff : ''}</span>
													<span className="laptimeDiff">{checkpoint.laptimeDiff ? Duration(checkpoint.laptimeDiff).format() : ''}</span>
													<span className="laptimeDiffFromFirst">{checkpoint.laptimeDiffFromFirst ? Duration(checkpoint.laptimeDiffFromFirst).format() : ''}</span>
													<span className="laptimeDiffFromNext">{checkpoint.laptimeDiffFromNext ? Duration(checkpoint.laptimeDiffFromNext).format() : ''}</span>
												</td>)
											})}

										</tr>
									)})
								}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		)
	}
}