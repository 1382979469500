import * as React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import * as axios from 'axios';
import moment from 'moment';
import api from '../Components/ApiClient';


export default class DecoderPage extends React.Component<any, any> {

	constructor(props: any) {

		super(props);
	}

	componentDidMount() {
		this.loadCheckpoints();
		this.loadCompetitors();

		var decoderAddress = window.localStorage.getItem('decoderAddress');
		if (decoderAddress) {
			this.setIpAddrees(decoderAddress);
		}
	}

	addLog(log) {
		this.setState(state => {
			state.log.push(moment().format('HH:mm:ss') + ' : ' + log);
		});

		this.forceUpdate();
	}

	addCheckpoint(line) {

		var data = line.replace("\n", "").split(';');

		console.log(Number(data[0]) / 1000);

		
		var checkpoint = {
			timestamp: new Date(Number(data[0])),
			transponder: data[1],
		};

		/*
		this.setState(state => {
			state.checkpoints.push(checkpoint);
		});
		*/

		this.uploadCheckpoint(checkpoint);
	}

	addCheckpoints(lines) {
		for (var line of lines.split('\n')) {
			line = line.replace('\r', '');
			if (line != '') {
				this.addCheckpoint(line);
			}
		}
	}

	async loadCheckpoints() {
		var response = await api.request('checkpoints?raceid=' + this.props.match.params.raceid);
		this.setState({ checkpoints: response.reverse() });		
	}

	async loadCompetitors() {
		var response = await api.request('competitors/inrace?raceid=' + this.props.match.params.raceid);

		this.setState({
			competitors: response.reduce((map, obj) => (map[obj.id] = obj, map), {})
		});
	
	}

	async uploadCheckpoint(checkpoint) {

		try {
			var response = await api.post('checkpoints/add/' + this.props.match.params.raceid, { timestamp: checkpoint.timestamp, transponderId: checkpoint.transponder });
			this.addLog('Uploaded checkpoint: ' + checkpoint.timestamp.toUTCString() + " - " + checkpoint.transponder + " - " + response.competitorId);
			this.setState({ checkpoints: [response, ...this.state.checkpoints] });
		}
		catch (e) {
			this.addLog('Failed to upload checkpoint: ' + checkpoint.timestamp.toUTCString() + " - " + checkpoint.transponder);
			this.addLog(e.message);
		}		
	}

	async connect() {

		if (this.state.checkpoints.length > 0) {
			var timestamp = moment(this.state.checkpoints[0].timestamp).valueOf();
			this.addLog('Geting checkpoints from ' + timestamp);
			this.getCheckpointsFromTimestamp(timestamp);
		}
		else {
			this.getAllCheckpoints();
		}

		this.addLog('Connecting decoder with web socket');

		var webSocket = new WebSocket("ws://" + this.state.ipAddress + "/ws");
		webSocket.onmessage = (event) => {

			this.addCheckpoint(event.data);
		}

		webSocket.onopen = (event) => {
			console.log('open', event);

			this.addLog('Web socket connetion open ');
			this.setState({ websocketStatus: 'connected' });
		}

		webSocket.onclose = (event) => {
			console.log('close', event);

			this.addLog('Web socket connetion closed ');			
			this.setState({ websocketStatus: 'disconnected' });
		}

		webSocket.onerror = (event) => {
			console.log('error', event);

			this.addLog('Web socket connetion error ');			
			this.setState({ websocketStatus: 'error' });
		}

		/*
		var statusRequest = await axios.default.get('http://' + this.state.ipAddress + "/status");
		if (statusRequest.data == "Ok" && statusRequest.status == 200) {
		}

		alert(statusRequest.data);
		*/

		/// moment.unix(Number(data[0]) / 1000)checkpoint.date.toISOString()
	}

	async resetCheckpoints() {
		await api.post('checkpoints/reset/' + this.props.match.params.raceid, null);
		this.addLog('Cleared all checkpoints');
		this.setState( { checkpoints: [] });
	}

	async getAllCheckpoints() {
		var statusRequest = await axios.default.get('http://' + this.state.ipAddress + "/checkpoints");
		if (statusRequest.status == 200) {

			this.addCheckpoints(statusRequest.data);
		}
	}

	async getCheckpointsFromTimestamp(timestamp) {
		var statusRequest = await axios.default.get(`http://${this.state.ipAddress}/checkpointsfrom?timestamp=${timestamp}`);
		if (statusRequest.status == 200) {
			this.addCheckpoints(statusRequest.data);
		}
	}

	async insertCheckpoints() {
		var chekcpoints = window.prompt('Checkpoint data');
		this.addCheckpoints(chekcpoints);
	}

	async archiveCheckpoints() {
		if (confirm('Are you sure you want to archive checkpoints on the decoder')) {
			var statusRequest = await axios.default.get('http://' + this.state.ipAddress + "/archivecheckpoints");
			if (statusRequest.status == 200) {
				this.addLog('Archived checkpoints');
			}
		}
	}

	async trashCheckpoints() {
		if (confirm('Are you sure you want to trash checkpoints on the decoder')) {
			var statusRequest = await axios.default.get('http://' + this.state.ipAddress + "/trashcheckpoints");
			if (statusRequest.status == 200) {
				this.addLog('Trash checkpoints');
			}
		}
	}

	async setTime() {
		var epoch = Math.round((new Date()).valueOf() / 1000);

		var statusRequest = await axios.default.get('http://' + this.state.ipAddress + "/updatesettings?time=" + epoch);
		if (statusRequest.status == 200) {
			this.addLog('Time set');
		}	
	}

	setCheckpointState(checkpoint, values) {
		var index = this.state.checkpoints.indexOf(checkpoint);

		let checkpointsCopy = [...this.state.checkpoints];
		let checkpointCopy = { ...checkpointsCopy[index], ...values };
		checkpointsCopy[index] = checkpointCopy;
		this.setState({ checkpoints: checkpointsCopy });
	}

	deleteCheckpoint(checkpoint) {
		
		api.delete('checkpoints/delete/' + checkpoint.id).then(response => {
			this.setCheckpointState(checkpoint, { deleted: true });
		});
	}

	undeleteCheckpoint(checkpoint) {
		api.put('checkpoints/undelete/' + checkpoint.id, null).then(response => {
			this.setCheckpointState(checkpoint, { deleted: false });
		});
	}

	link(transponderId,competitorId) {
		api.post(`checkpoints/linkcheckpoints?raceid=${this.props.match.params.raceid}&competitorid=${competitorId}&transponderId=${transponderId}`, null).then(response => {

			this.loadCheckpoints();
			this.setState({ competitorId: '' });
			//this.setCheckpointState(checkpoint, { deleted: false });
		});
	}

	setIpAddrees(ipAddress) {
		this.setState({ ipAddress: ipAddress });
		localStorage.setItem('decoderAddress', ipAddress);
	}

	getDisplayLog() {
		var logLength = this.state.log.length;
		var displayLength = 5;
		if (logLength > displayLength && this.state.showLog == false) {
			console.log(this.state.log.slice(logLength - displayLength));
			return this.state.log.slice(logLength - displayLength)
		}
		else {
			return this.state.log;
		}
	}

	state = {
		ipAddress: '192.168.1.170',
		checkpoints: [],
		log: [],
		competitors: {},
		competitorId: '',
		websocketStatus: 'disconnected',
		showLog: false
	};
	
	render() {		
		return (
			<div>
				<div>
					<h2>Connect to decoder</h2>					
				</div>

				<div>
					<div className="form-group">
						<label htmlFor="name">Decoder ip addess</label>
						<input type="text" id="name" className="form-control" value={this.state.ipAddress} onChange={(event) => this.setIpAddrees( event.target.value )} />
					</div>

					<ButtonGroup>
						<Button bsStyle="primary" onClick={() => this.connect()} disabled={this.state.websocketStatus=='connected'}>Connect</Button>

						<Button bsStyle="primary" onClick={() => this.insertCheckpoints()}>Insert Checkpoints</Button>

						<Button bsStyle="warning" onClick={() => this.getAllCheckpoints()}>Get All Checkpoints</Button>						

						<Button bsStyle="warning" onClick={() => this.resetCheckpoints()}>Reset race checkpoints</Button>

						<Button bsStyle="warning" onClick={() => this.archiveCheckpoints()}>Archive decoder checkpoints</Button>
						<Button bsStyle="warning" onClick={() => this.trashCheckpoints()}>Trash decoder checkpoints</Button>
						<Button bsStyle="warning" onClick={() => this.setTime()}>Set time</Button>
					</ButtonGroup >

					<div>				
						Log:
						
						{this.getDisplayLog().map((log, i) => (
							<div key={log}>{log}</div>
						))}
								
					</div>

					<table className="table table-striped">
						<thead>
						<tr>
							<th>Timestamp</th>
							<th>Transponder</th>
							<th>Competitor</th>
						</tr>
						</thead>
						<tbody>
							{this.state.checkpoints.map((checkpoint,index) => (
								<tr key={checkpoint.timestamp} className={checkpoint.deleted ? 'bg-danger':''}>
									<td>
										{moment(checkpoint.timestamp).format('HH:mm:ss')}					
									</td>
									<td>{checkpoint.transponderId}</td>
									<td>
										{checkpoint.competitorId && this.state.competitors[checkpoint.competitorId] ? this.state.competitors[checkpoint.competitorId].name :
											<div>

												<select value={this.state.competitorId} onChange={(e) => this.setState({ competitorId: e.target.value })}>
													<option value="">--- Choose competitor ---</option>
													{Object.keys(this.state.competitors).map((key) => 
														<option key={key} value={key}>{this.state.competitors[key].name} (#{this.state.competitors[key].number}) </option>)}
												</select>

												{this.state.competitorId ? <Button bsStyle="primary" onClick={() => this.link(checkpoint.transponderId, this.state.competitorId)}>Link</Button> : null}
												
											</div>}
									</td>
									<td>
										{checkpoint.deleted ?
											<Button bsStyle="success" onClick={() => this.undeleteCheckpoint(checkpoint)}>Undelete</Button> :
											<Button bsStyle="danger" onClick={() => this.deleteCheckpoint(checkpoint)}>Delete</Button>
										}
											
									</td>
								</tr>
							))}
						</tbody>
					</table>

				</div>
			</div>
		)
	}
}