import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { App } from './App';
import { BrowserRouter, HashRouter } from 'react-router-dom';
//import './index.css';

ReactDOM.render(

	<HashRouter>
		<App />
	</HashRouter>
	
	,
	document.getElementById('root')
);
