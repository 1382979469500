

class Duration {
	constructor(milliseconds: number) {
		this.totalMilliseconds = milliseconds;

		this.absMilliseconds = Math.abs(milliseconds);

		
		this.days = Math.floor(this.absMilliseconds / 86400000);
		this.hours = Math.floor(this.absMilliseconds / 3600000) % 24;
		this.minutes = Math.floor(this.absMilliseconds / 60000) % 60
		this.seconds = this.absMilliseconds / 1000 % 60;
		this.milliseconds = this.absMilliseconds % 1000;
	}

	public totalMilliseconds = 0;

	public absMilliseconds = 0;

	public days = 0;

	public hours = 0;

	public minutes = 0;

	public seconds = 0;

	public milliseconds = 0 

	private pad = (i) => {
		return ((i < 10) ? '0' : '') + i;
	}

	public format = () =>	{
		return (this.totalMilliseconds < 0 ? '-' : '') +
			(this.absMilliseconds > 86400000 ? this.pad(this.days) + 'd ' : '') +
			(this.absMilliseconds > 3600000 ? this.pad(this.hours) + 'h ' : '') +
			this.pad(this.minutes) + ':' + this.pad(Math.floor(this.seconds)); // + '.' + milliseconds;
	}
}

export default (milliseconds) => new Duration(milliseconds);
