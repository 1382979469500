import * as React from 'react';
import api from './ApiClient';
import moment from 'moment';
import Security from './Security';
import {Link} from "react-router-dom";

var styles = require('./SeriesList.scss');


export default class SeriesList extends React.Component<any, any> {

	constructor(props: any) {

		super(props);

		this.state = {
			series: [],
			loaded:false
		};
		
	}

	componentDidMount() {
		api.request('series').then(data => this.setState({ series: data, loaded : true }));	
	}
	
	render() {		
		return (			
			<div>

				{!this.state.loaded && <div className="loader"></div>}

				<table className="table">
					<thead>
						<tr>
							<th>Year</th>
							<th>Name</th>
							{Security.isAdmin() && <th>Actions</th> }
						</tr>
					</thead>
					<tbody>
					{this.state.series.map(series => {
						return (
							<tr key={series.id}>
								<td>{moment(series.start).format('YYYY')}</td>
								<td>
									<Link to={'/series/' + series.id}>{series.name}</Link>
								</td>

								{Security.isAdmin() &&
									<td className="button-group d-flex justify-content-end">
									
										<Link className="btn btn-primary" to={'series/edit/' + series.id}>
											<span className="fa fa-pencil"></span> Edit
										</Link>

										<Link className="btn btn-primary" to={'series/' + series.id + '/editclasses'}>
											<span className="fa fa-pencil"></span> Classes
										</Link>

									</td>
								}
							</tr>
						)
					})}
					</tbody>
				</table>
				{Security.isAdmin() &&
					<Link to="/series/add" className="btn btn-success">
						<span className="fa fa-plus"></span>Add
					</Link>
				}
			</div>
		)
	}
}
