import * as React from 'react';
import api from '../Components/ApiClient';
import EditCompetitor from '../Components/EditCompetitor';


export default class HomePage extends React.Component<any, any> {

	constructor(props: any) {	
		super(props);
	}

	componentDidMount() {
		var data = api.request(`races/${this.props.match.params.raceid}`).then(data => {					
			this.setState({ race: data });
			
		});	
	}


	state = {
		race:null
	};

	//navigate = useHistory();

	
	render() {				
		return (			
			
			<div>				
				<h2>Add competitor to race</h2>
			
				{this.state.race &&
					<EditCompetitor race={this.state.race} competitorId={''} onCancel={() => { this.props.history.push('/race/' + this.props.match.params.raceid); }} onSave={() => { this.props.history.push('/race/' + this.props.match.params.raceid); } } />					
				}
			</div>
		)
	}
}
