import * as React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { Link } from "react-router-dom";
import api from '../Components/ApiClient';

import RaceCompetitorsList from '../Components/RaceCompetitorsList'



export default class RaceTimerDataPage extends React.Component<any, any> {

	constructor(props: any) {
	
		super(props);
	}

	async componentDidMount() {
		await this.loadCompetitors();
	}

	async loadCompetitors() {
		var competitorsPromise = api.request('competitors/inrace?raceid=' + this.props.match.params.raceid).then(data => {
			this.setState({
				competitors: data
			});
		});

		await competitorsPromise;
	}

	stripAccent(str) {
		const icelandicChars = ['\xE1', '\xC1', '\xF0', '\xD0', '\xE9', '\xC9', '\xED', '\xCD', '\xF3', '\xD3', '\xFA', '\xDA', '\xFD', '\xDD', '\xFE', '\xDE', '\xE6', '\xC6', '\xF6', '\xD6'];
		const englishChars = ['a', 'A', 'd', 'D', 'e', 'E', 'i', 'I', 'o', 'O', 'u', 'U', 'y', 'Y', 'th', 'Th', 'ae', 'Ae', 'o', 'O'];

		console.log(str, str.indexOf('�'));

		for (let i = 0; i < icelandicChars.length; i++) {
			console.log(str,str.indexOf('�'));
			const regex = new RegExp(icelandicChars[i], 'g');
			str = str.replace(regex, englishChars[i]);
		}

		return str;
	}

	getFirstWords(text, maxLength) {
		const words = text.split(' ');
		let result = words[0];
		let index = 1;

		while (index < words.length && (result + ' ' + words[index]).length <= maxLength) {
			result += ' ' + words[index];
			index++;
		}

		return result;
	}

	removeNonEnglishChars(str) {
		return str.replace(/[^A-Za-z\s]/g, '');
	}


	convertToHex(input) {
		let number = Number(input);
		if (isNaN(number)) {
			throw new Error("Input is not a valid number");
		}
		return number.toString(16);
	}

	formatName(num, name) {
		return this.getFirstWords(num + ' ' + this.stripAccent(name), 20).toUpperCase()
	}

	state = {
		competitors: [],		
	}
	
	render() {		
		return (
			<div>

				<div>
					<h2>Race timer data</h2>					
				</div>

				<div>
					<table className="table">
						<thead>
							<tr>
								<th>Number</th>								
								<th>Group</th>
								<th>Transponder</th>
								<th></th>
								<th></th>
							</tr>
						</thead>
						<tbody>

							{this.state.competitors.map((competitor, index) => {
								return (
									<tr key={competitor.id}>
										<td>{this.formatName(competitor.number, competitor.name)}</td>
										<td>{this.stripAccent(competitor.group).toUpperCase().substring(0, 10)}</td>
										<td>{this.convertToHex(competitor.transponder).toUpperCase()}</td>
										<td>1.1.2023</td>
										<td>1.1.2023</td>
									</tr>
								)
							})}

						</tbody>
					</table>					
				</div>
			</div>
		)
	}
}