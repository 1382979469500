import * as React from 'react';
import api from './ApiClient';
import moment from 'moment';

import ResultsTable from './ResultsTable'; 
import CompetitorDetails from './CompetitorDetails'; 
import ClassSelector from './ClassSelector';
import Duration from './Duration';

import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';

import Security from './Security';
import { Link } from "react-router-dom";


interface RaceResultsCmponentState {

	race:any,
	checkpoints: Array<any>
	results: any,	
	competitors: Array<any>,
	seriesClassId:string,

	showDetails: any,
	now:Date
};

export default class RaceResults extends React.Component<any, RaceResultsCmponentState> {


	constructor(props) {
		super(props);

		this.setClass = this.setClass.bind(this);
	}

	private interval: any;

	componentDidMount() {Security.isAdmin()
		var raceid = this.props.match.params.raceid;	

		var racePromise = api.request('races/' + raceid).then(data => {
			this.setState({
				race: data
			});

			if (!data.finished) {
				this.setupHub();

			}
		});

		var competitorsPromise = api.request('competitors/inrace?raceid=' + raceid).then(data => {		
			this.setState({
				competitors: data.reduce((map, obj) => (map[obj.id] = obj, map), {})				
			});
		});

		var checkpointPromise = this.getCheckpointData();

			
		Promise.all([racePromise, checkpointPromise, competitorsPromise]).then(() => {			
            this.setState({ results: this.calculateReults(this.state.checkpoints, this.state.competitors, moment(this.state.race.start)) });
		});		
		
		api.request('races/' + raceid).then(data => {
			this.setState({
				race: data
			});
        });


		this.interval = setInterval(() => this.tick(), 1000);
	}

	setupHub = () => {
		const connection = new HubConnectionBuilder()
			.withUrl("/checkpointhub")
			//.configureLogging(signalR.LogLevel.Information)
			.build();

		connection.on("CheckpointAdded", (checkpoint, competitor) => {

			if (!this.state.competitors[competitor.id]) {
				this.setState({ competitors: { ...this.state.competitors, [competitor.id]: competitor } });
			}

			this.setState({ checkpoints: [...this.state.checkpoints, checkpoint] });

			this.updateResult();
		});

		var connectionPromise = connection.start().catch(err => console.error(err.toString()));

		connectionPromise.then(() => {
			connection.invoke("JoinGroup", "Race" + this.props.match.params.raceid).catch(err => console.error(err.toString()));
		});
	}

	getCheckpointData = () => {
		return api.request('checkpoints/?raceid=' + this.props.match.params.raceid).then(data => {
			data.sort((a, b) => this.stringOrder(a.timestamp, b.timestamp));
			this.setState({ checkpoints: data });
		});
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	private tick = () => {
		this.setState({ now: new Date() });
	}	

	state = {
		race: {
			name: null,
			eventId: null,
			start: null,
			seriesId: null,
			finished:true
		},
		checkpoints: [],
		results: [],
        competitors: null,
        
		tableClass: 'showLapTime',
		seriesClassId: null,
		showDetails: null,
        now: new Date(),
        
	};

	stringOrder = (a: string, b: string) => (a < b) ? -1 : (a > b) ? 1 : 0;	

	calculateReults = (checkpoints, competitors, racestart) => {
		var timer = performance.now();

		var competitorsCheckpoints = [];
		//var competitors = {};

		var laps = [];

		var fastestLap = null

		for (var checkpoint of checkpoints) {
			

			var competitorId = checkpoint.competitorId;
			/*
			
			if (competitors[competitorId] === undefined) {
				competitors[competitorId] = { id: competitorId, name: 'Huh?' };//checkpoint.competitor;					
			}
			*/

            var competitor = competitors[competitorId];

            if (competitor) {           	
			    if (competitorsCheckpoints[competitorId] === undefined) {
				    competitorsCheckpoints[competitorId] = [];
			    }

			    var competitorCheckpoints = competitorsCheckpoints[competitorId];


			    var lastLap = null;

			    var lapnr = competitorCheckpoints.length - 1;
			    while (lapnr >= 0 && lastLap == null) {
				    if (!competitorCheckpoints[lapnr].deleted) {
					    lastLap = competitorCheckpoints[lapnr];
				    }

				    lapnr--;
			    }

			    //var lapNumber = competitorCheckpoints.length;

			    var lapNumber = (lastLap == null) ? 0 : lastLap.lapNumber;

			    if (!laps[lapNumber]) {
				    laps[lapNumber] = [];
			    }

			    var lapCheckpoints = laps[lapNumber];

			    var nextCompetitor = lapCheckpoints.length > 0 ? lapCheckpoints[lapCheckpoints.length - 1] : null;
			    var firstCompetitor = lapCheckpoints.length > 0 ? lapCheckpoints[0] : null;

			    var timestamp = moment(checkpoint.timestamp);

			    timestamp.diff(racestart);

			    checkpoint.timestamp = timestamp;
			    checkpoint.totaltime = timestamp.diff(racestart);
			
			
			    var lastLapTimestamp = lastLap == null ? racestart : lastLap.timestamp;

			    checkpoint.laptime = timestamp.diff(lastLapTimestamp);
			    checkpoint.behindFirst = firstCompetitor == null ? null : timestamp.diff(firstCompetitor.timestamp);
			    checkpoint.behindNext = nextCompetitor == null ? null : timestamp.diff(nextCompetitor.timestamp);
			    checkpoint.pos = laps[lapNumber].length + 1;
			    checkpoint.posDiff = (lastLap ==  null ? null : lastLap.pos - checkpoint.pos);
			    checkpoint.laptimeDiffFromFirst = firstCompetitor ? checkpoint.laptime - firstCompetitor.laptime : null;
			    checkpoint.laptimeDiffFromNext = nextCompetitor ? checkpoint.laptime - nextCompetitor.laptime : null;
			    checkpoint.laptimeDiff = (lastLap == null ? null : checkpoint.laptime - lastLap.laptime);
			    checkpoint.lapNumber = (lastLap == null ? 1 : lastLap.lapNumber + 1);

			    if (!checkpoint.deleted) {

				    if (checkpoint.lapNumber != 1) {
					    if (competitor.fastestLap == null || competitor.fastestLap.laptime > checkpoint.laptime) {
						    if (competitor.fastestLap != null) competitor.fastestLap.bestLap = false;
						    checkpoint.bestLap = true;
						    competitor.fastestLap = checkpoint;
					    }


					    if (fastestLap == null || fastestLap.laptime > checkpoint.laptime) {
						    if (fastestLap != null) fastestLap.fastestLap = false;
						    checkpoint.fastestLap = true;
						    fastestLap = checkpoint;
					    }
				    }
			
				    laps[lapNumber].push(checkpoint);
			    }
			

			    competitorCheckpoints.push(checkpoint);

            }
		}

		var results = [];


		for (var id in competitorsCheckpoints) {
			results.push({ competitor: competitors[id], checkpoints: competitorsCheckpoints[id] });
		}

		results.sort((a, b) => {
			var ac = a.checkpoints.filter(c => !c.deleted);
			var bc = b.checkpoints.filter(c => !c.deleted);
			return (ac.length == bc.length) ?
				this.stringOrder(ac[ac.length - 1].timestamp, bc[bc.length - 1].timestamp)
				: bc.length - ac.length
			}
		)

		results.map((result, index) => result.position = index + 1);

		console.log('Calculation took: ' + (performance.now() - timer) + ' ms');


		return results;
	}

	updateResult = () => {
		if (this.state.seriesClassId) {
			this.setState({ results: this.calculateReults(this.state.checkpoints.filter(x => this.state.competitors[x.competitorId] && this.state.competitors[x.competitorId].seriesClassId == this.state.seriesClassId), this.state.competitors, moment(this.state.race.start)) });
		}
		else {
			this.setState({ results: this.calculateReults(this.state.checkpoints, this.state.competitors, moment(this.state.race.start)) });
		}
	};

	setClass = (seriesClassId) => {
		this.setState({ seriesClassId: seriesClassId }, () => {
			this.updateResult();
		});					
	};	

	competitorClicked = (competitor) => {
		this.setState({showDetails:competitor})
	}

	checkpointDataChanged = () => {
		this.getCheckpointData().then(() => {
			this.updateResult();
		});
	}

	render() {
		if (this.state.showDetails) {
			return (
				<div>
					<CompetitorDetails results={this.state.results.filter(x => x.competitor.id == this.state.showDetails.id)} showETA={!this.state.race.finished} checkpointDataChanged={() => this.checkpointDataChanged()} />
					<button onClick={() => this.competitorClicked(null)} className="btn btn-success">Close</button>
				</div>
			);
		}
		else {
			return (				
				<div>
					<h2>{this.state.race.name}</h2>

					{!this.state.race.finished && <h3>{Duration(moment.duration(moment().diff(moment(this.state.race.start))).asMilliseconds()).format()}</h3>}  
					<ClassSelector seriesId={this.state.race.seriesId} onChange={this.setClass} showAllButton={true}></ClassSelector>
					<div className="mt-2">
                        <ResultsTable results={this.state.results} competitors={this.state.competitors} onCompetitorClick={this.competitorClicked} showETA={!this.state.race.finished} />
					</div>
					<div>
						<Link to={'/event/' + this.state.race.eventId} className="btn btn-success">Back</Link>
					</div>
				</div>
			);
		}
	}
}