import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Route, Switch } from 'react-router-dom';

import HomePage from './Pages/HomePage';
import CompetitorsPage from './Pages/CompetitorsPage';
import RaceTimerDataPage from './Pages/RaceTimerData';
import EditSeries from './Components/EditSeries';
import EditSeriesClasses from './Components/EditSeriesClasses';
import SeriesPage from './Pages/SeriesPage'; 
import EditEvent from './Components/EditEvent'; 
import RaceList from './Components/RaceList'; 
import EditRace from './Components/EditRace'; 
import ImportRaceData from './Components/ImportRaceData';
import ImportCompetitors from './Components/ImportCompetitors'; 
import ImportLogs from './Components/ImportLogs'; 
import RaceResults from './Components/RaceResults'; 
import EditCompetitor from './Components/EditCompetitor';
import LoginForm from './Components/LoginForm'; 
import RaceCompetitorPage from './Pages/RaceCompetitorPage'; 
import DecoderPage from './Pages/DecoderPage';
import AddCompetitorPage from './Pages/AddCompetitorPage'; 


export class App extends React.Component<any,any>
{
	constructor(props:any)
	{
		super(props);
	}

	render() {
		return (
			<div className="d-flex flex-column flex-fill">
			
				<Switch>
					<Route exact path="/" component={HomePage} />
					<Route exact path="/Series/Add" component={EditSeries} />
					<Route exact path="/Series/Edit/:id" component={EditSeries} />
					<Route exact path="/Series/:seriesid" component={SeriesPage} />
					<Route exact path="/Series/:seriesid/EditClasses" component={EditSeriesClasses} />
					<Route exact path="/Series/:seriesid/Events/Add" component={EditEvent} />
					<Route exact path="/Series/:seriesid/Events/Edit/:eventid" component={EditEvent} />
					<Route exact path="/Event/:eventid/" component={RaceList} />
					<Route exact path="/Event/:eventid/Races/Add/" component={EditRace} />
					<Route exact path="/Races/Edit/:raceid" component={EditRace} />
					<Route exact path="/ImportAccess/:raceid" component={ImportRaceData} />
					<Route exact path="/ImportLog/:raceid" component={ImportLogs} />
					<Route exact path="/ImportCompetitors/:raceid" component={ImportCompetitors} />
					<Route exact path="/Race/:raceid" component={RaceResults} />
					<Route exact path="/Race/:raceid/addCompetitor" component={AddCompetitorPage} />
					<Route exact path="/Decoder/:raceid" component={DecoderPage} />
					<Route exact path="/Race/:raceid/Competitors" component={RaceCompetitorPage} />
					<Route exact path="/Race/:raceid/RaceTimer" component={RaceTimerDataPage} />
					<Route exact path="/login" component={LoginForm} />
					<Route exact path="/Series/:seriesid/competitors" component={CompetitorsPage} />
				</Switch>

			</div>

		);
	}
}
/*
					<Route exact path="/Series/Add" component={EditSeries} />
					<Route exact path="/Series/Edit/:id" component={EditSeries} />
					<Route exact path="/Series/:seriesid/Events/" component={EventList} />
					<Route exact path="/Series/:seriesid/Events/Add" component={EditEvent} />
					<Route exact path="/Series/:seriesid/Events/Edit/:id" component={EditEvent} />
					<Route exact path="/Event/:eventid/" component={RaceList} />
					<Route exact path="/Races/Edit/:id" component={EditRace} />

*/