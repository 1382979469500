import * as React from 'react';
import api from './ApiClient';
import { Button, ButtonGroup } from 'react-bootstrap';
import Security from './Security';
import PointsTable from './PointsTable';
import ClassSelector from './ClassSelector';
import moment from 'moment';
import { Link } from "react-router-dom";

export default class RaceList extends React.Component<any, any> {

	componentDidMount() {
		api.request('eventraces/?eventid=' + this.props.match.params.eventid).then(data => {
			this.setState({ races: data });
		});

		api.request('events/' + this.props.match.params.eventid).then(data => {
			this.setState({ event: data });
		});
	}

	state = {
		races: [],
		event: { id: '', name: '', seriesId: '' },
		classId: ''
	};

	changeClass = (classId) => {
		this.setState({ classId: classId });
	}

	setActiveRace = (raceId,active) => {
		api.put('races/' + raceId + '/active/' + (active?'true':'false'),null).then(data => {
			var races = this.state.races.map(race => {
				race.active = race.id == raceId ? active : false;
				return race;
			});

			this.setState({ races: races });		
		});
	}

	render() {
		return (
			<div>
				<h2>{this.state.event.name} - Races</h2>

				<table className="table">
					<thead>
						<tr>
							<th>Name</th>
							<th>Start time</th>
							<th>Active</th>
							<th></th>
							{Security.isAdmin() && <th className="d-flex justify-content-end">Actions</th>}
						</tr>
					</thead>
					<tbody>

						{this.state.races.map(race => {
							return (
								<tr key={race.id}>
									<td><Link to={'/race/' + race.id}>{race.name}</Link></td>
									<td>{moment(race.start).format('HH:mm')}</td>
									<td>{race.active ? 'true' : 'false'}</td>
									<td>{}</td>
									{Security.isAdmin() &&
										<td className="d-flex justify-content-end">
											<ButtonGroup >
												<Link className="btn btn-primary" to={'/races/edit/' + race.id}>Edit</Link>
												<Link className="btn btn-primary" to={'/race/' + race.id + '/competitors/'}>Competitors</Link>
												{/*
													<Link className="btn btn-warning" to={'/ImportAccess/' + race.id}>Import(DB)</Link>
													<Link className="btn btn-warning" to={'/ImportLog/' + race.id}>Import(Log)</Link>*
												*/}
												<Link className="btn btn-warning" to={'/Decoder/' + race.id}>Decoder</Link>												
												<Button bsStyle="success" onClick={() => this.setActiveRace(race.id, !race.active)}>Set Active</Button>
											</ButtonGroup >
										</td>
									}
								</tr>
							)
						})}

					</tbody>
				</table>

				<div>
					<ButtonGroup>
						<Link to={'/series/' + this.state.event.seriesId} className="btn btn-success">
							Back
						</Link>

						{Security.isAdmin() &&
							<Link to={'/event/' + this.props.match.params.eventid + '/races/add'} className="btn btn-primary">
								Add
							</Link>
						}
					</ButtonGroup >

				</div>

				<h2>Result</h2>

				<ClassSelector seriesId={this.state.event.seriesId} onChange={this.changeClass} showAllButton={false}></ClassSelector>

				<PointsTable eventId={this.state.event.id} classId={this.state.classId} />



			</div>
		)
	}
}