import * as React from 'react';
import api from './ApiClient';
import moment from 'moment';
import Security from './Security';
import { Button, ButtonGroup } from 'react-bootstrap';
import EditCompetitor from './EditCompetitor';

interface EditCompetitorProps {
	raceId: string,
};

export default class RaceCompetitorsList extends React.Component<EditCompetitorProps, any> {

	constructor(props, context) {
		super(props);

		this.competitorSaved = this.competitorSaved.bind(this);
	}

	async componentDidMount() {
		await this.loadCompetitors();
		this.getRace();
	}

	async loadCompetitors() {
		var competitorsPromise = api.request('competitors/inrace?raceid=' + this.props.raceId).then(data => {
			this.setState({
				competitors: data
			});			
		});

		await competitorsPromise;
	}

	state = {
		competitors: [],
		editIndex: -1,
		race: {}
	}

	getRace() {
		api.request('races/' + this.props.raceId).then(data => {
			this.setState({
				race: data
			});
		});
	}

	async deleteCompetitor(competitor) {
		if (confirm('Are you sure you want to delete competiror #' + competitor.number + " - " + competitor.name + '?')) {
			await api.delete('Competitors/Competitor/' + competitor.id);
			this.loadCompetitors();
		}
	}

	competitorSaved(competitor) {

		var competitors = this.state.competitors;

		competitors.splice(this.state.editIndex, 1, { ...competitors[this.state.editIndex], ...competitor });
		
		this.setState({ competitors: competitors, editIndex:-1 });		
	}

	render() {
		if (this.state.editIndex != -1) {
			return (
				<div>
					<EditCompetitor race={this.state.race} competitorId={this.state.competitors[this.state.editIndex].id} onCancel={() => this.setState({ editIndex: -1 })} onSave={this.competitorSaved} />
				</div>
			);
		}

		else
		return (
			<div>
				<table className="table">
					<thead>
						<tr>
							<th>Number</th>
							<th>Name</th>					
							<th>SSN</th>
							<th>Age</th>
							<th>Transponder</th>
							<th>Group</th>
							<th></th>
							{Security.isAdmin() && <th className="d-flex justify-content-end">Actions</th>}
						</tr>
					</thead>
					<tbody>

						{this.state.competitors.map((competitor,index) => {
							return (
								<tr key={competitor.id}>
									<td>{competitor.number}</td>
									<td>{competitor.name}</td>
									<td>{competitor.ssn}</td>
									<td>{competitor.age}</td>
									<td>{competitor.transponder}</td>
									<td>{competitor.group}</td>
									<td></td>

									{Security.isAdmin() &&
										<td className="d-flex justify-content-end">
											<ButtonGroup>
												<Button bsStyle="warning" onClick={() => this.deleteCompetitor(competitor)}>Delete</Button>
												<Button bsStyle="primary" onClick={() => this.setState({ editIndex: index })}>Edit</Button>
											</ButtonGroup >
										</td>
									}
								</tr>
							)
						})}

					</tbody>
				</table>
			</div>
		)
	}

}