import * as React from 'react';
import api from './ApiClient';
import { Button, ButtonToolbar, FormGroup, ControlLabel, FormControl, Modal } from 'react-bootstrap';
import moment from 'moment';



interface ImportLogsDataParams {
	match: any
}

export default class ImportLogs extends React.Component<ImportLogsDataParams, any> {

	constructor(props) {
		super(props);

		//this.startImport = this.startImport.bind(this);
		this.logChanged = this.logChanged.bind(this);
	}

	componentWillMount() {		
		var raceId = this.props.match.params.raceid;
		this.getCheckpoints(raceId);
		this.getRace(raceId)
	}

	stringOrder = (a: string, b: string) => (a < b) ? -1 : (a > b) ? 1 : 0;	

	getCheckpoints(raceId) {
		console.log('mmmxx');
		return api.request('checkpoints/?raceid=' + raceId).then(data => {
			data.sort((a, b) => this.stringOrder(a.timestamp, b.timestamp));
			this.setState({ checkpoints: data });
		});
	}

	getRace(raceId) {
		api.request('races/' + raceId).then(data => {
			this.setState({
				race: data
			});

			this.getCompetitorsInSeries(data.seriesId);			
		});
	}

	getCompetitorsInSeries(seriesId) {
		var competitorsPromise = api.request('competitors/inseries?seriesid=' + seriesId).then(data => {
			this.setState({
				competitors: data.reduce((map, obj) => (map[obj.id] = obj, map), {})
			});
		});
	}


	getCompetitorsInRace(raceid) {
		var competitorsPromise = api.request('competitors/inrace?raceid=' + raceid).then(data => {
			this.setState({
				competitorsInRace: data.reduce((map, obj) => (map[obj.transponder] = obj, map), {})
			});
		});
	}


	state = {
		raceId:'',
		log: '',
		logLinesParsed: [],
		checkpoints: [],
		race: null,
		competitors: [],
		competitorsInRace: []
	}

	logChanged(e) {
		var log = e.target.value;
		(e) => this.setState({ log: log });

		var linePattern = /^([0-9]+);([0-9]+)$/mig;

		var match;

		//let competitors = Object.assign({}, this.state.competitors);
		var logLinesParsed = [];
		while (match = linePattern.exec(log)) {

			logLinesParsed.push({				
				timestamp: new Date(parseInt(match[1])),
				transponder: match[2]
			});
		}

		console.log(logLinesParsed);

		this.setState({ logLinesParsed: logLinesParsed });
	}

	render() {
		return (
			<div>
				<textarea onChange={this.logChanged} value={this.state.log} />

				<table className="table">
					<thead>
						<tr>
							<th>Timestamp</th>
							<th>Transponder</th>
							<th>Competitor</th>
						</tr>
					</thead>
					<tbody>
						{this.state.checkpoints.map(cp => {
							return (
								<tr>
									<td>{moment(cp.timestamp).format('HH:mm:ss')}</td>
									<td>{cp.transponderId}</td>
									<td>{this.state.competitors[cp.competitorId] ? this.state.competitors[cp.competitorId].name : '*** UNKNOWN ***'}</td>
								</tr>
							)
						})}
					</tbody>
				</table>	
				
				<table className="table">
					<thead>
						<tr>
							<th>Timestamp</th>
							<th>Transponder</th>
						</tr>
					</thead>
					<tbody>
						{this.state.logLinesParsed.map(line => {
							return (
								<tr key={line.timestamp}>
									<td>{moment(line.timestamp).format('HH:mm:ss')}</td>
									<td>{line.transponder}</td>
								</tr>
							)
						})}
					</tbody>
				</table>	
			</div>			
		);
	}
}