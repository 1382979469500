import * as React from 'react';
import api from './ApiClient';
import Duration from './Duration';
import moment from 'moment';
import Security from './Security';

interface ResultsTableProps {	
	results: any,
	showETA: boolean,
	checkpointDataChanged: () => void,
};

export default class CompetitorDetails extends React.Component<ResultsTableProps, any> {

	componentDidMount() {	
		
	}

	state = {

		results: []

	};

	deleteCheckpoint = (checkpoint) => {
		api.delete('checkpoints/delete/' + checkpoint.id).then(response => {
			this.props.checkpointDataChanged();
		});
	}

	undeleteCheckpoint = (checkpoint) => {
		api.put('checkpoints/undelete/' + checkpoint.id,null).then(response => {
			this.props.checkpointDataChanged();
		});
	}

	splitCheckpoint = (checkpoint) => {
		api.post('checkpoints/split/' + checkpoint.id, null).then(response => {
			this.props.checkpointDataChanged();
		});
	}

	render() {
		return (
			<div>
				{this.props.results.map((result, index) => {
					var lastLap = result.checkpoints[result.checkpoints.length - 1];
					return (

					<div key={result.competitor.id}>
							<h2>#{result.position} - {result.competitor.name}</h2>
							{this.props.showETA && <h3>ETA: {moment(lastLap.timestamp).add(lastLap.laptime, 'ms').fromNow()}</h3>}

							<table className="table table-striped">
								<thead>
									<tr>
										<th colSpan={4}></th>
										<th colSpan={2} className="text-center table-info">Gap</th>									
										<th colSpan={2} className="text-center table-primary">Lap times diff</th>
									</tr>
									<tr>
										<th>Lap</th>
										<th>Pos</th>
										<th className="text-right">LapTime</th>
										<th className="text-right">Time</th>
										<th className="text-right table-info">First</th>
										<th className="text-right table-info">Next</th>
										<th className="text-right table-primary">First</th>
										<th className="text-right table-primary">Next</th>
										{Security.isAdmin() && [
												<th className="table-warning">Source</th>,
												<th className="table-warning">Actions</th>
										]}
									</tr>
								</thead>
								<tbody>
									{result.checkpoints.map((checkpoint, index) => {
										var rowStyle = {};
										if (checkpoint.deleted) {
											rowStyle['textDecoration'] = 'line-through';
											rowStyle['color'] = 'red';
										}
										if (!checkpoint.deleted || Security.isAdmin()) return (
											<tr key={checkpoint.timestamp} style={rowStyle}>
												<td className="">{checkpoint.lapNumber}</td>
												<td>
													{checkpoint.pos}
													{checkpoint.posDiff > 0 && <i className="fas fa-sort-up text-success"></i>}
													{checkpoint.posDiff < 0 && <i className="fas fa-sort-down text-danger"></i>}
												</td>

												<td className="text-right">
													{(checkpoint.bestLap && !checkpoint.fastestLap) && <i className="far fa-star text-warning"></i>}
													{checkpoint.fastestLap && <i className="fas fa-star text-warning"></i>}

													{Duration(checkpoint.laptime).format()}
												</td>

												<td className="text-right">
													{moment(checkpoint.timestamp).format("HH:mm:ss")}
												</td>


												<td className="text-right table-info">{checkpoint.behindFirst ? Duration(checkpoint.behindFirst).format() : '--'}</td>
												<td className="text-right table-info">{checkpoint.behindNext ? Duration(checkpoint.behindNext).format() : '--'}</td>

												<td className="text-right table-primary">{checkpoint.laptimeDiffFromFirst ? Duration(checkpoint.laptimeDiffFromFirst).format() : ''}</td>
												<td className="text-right table-primary">{checkpoint.laptimeDiffFromNext ? Duration(checkpoint.laptimeDiffFromNext).format() : ''}</td>


												{Security.isAdmin() && [
													<th className="table-warning">{checkpoint.source}</th>,
													<th className="table-warning">
														{checkpoint.deleted && <button onClick={(e) => this.undeleteCheckpoint(checkpoint)}>Undelete</button>}
														{!checkpoint.deleted && <button onClick={(e) => this.deleteCheckpoint(checkpoint)}>Delete</button>}
														<button onClick={(e) => this.splitCheckpoint(checkpoint)}>Split</button>
													</th>
												]}
											</tr>
										);}
									)}
								</tbody>
							</table>

						</div>
					)}
				)}
			</div>			
		)
	}
}