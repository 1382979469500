import * as React from 'react';
import api from './ApiClient';
import DatePicker from 'react-datepicker';
import moment from 'moment'; 
import { Link } from "react-router-dom";

var styles = require('react-datepicker/dist/react-datepicker.css');

export default class EditSeries extends React.Component<any, any> {
	constructor(props) {
		super(props);

		this.saveSeries = this.saveSeries.bind(this);
	}

	componentDidMount() {
		if (this.props.match.params.id) {
			this.loadSeries(this.props.match.params.id);
		}
	}

	loadSeries(id) {
		api.request('series/' + id).then(data => {
			this.setState({
				seriesId:data.id,
				name: data.name,
				start: moment(data.start),
				end: moment(data.end)
			});
		});
	}


	state = {
		name: '',
		seriesId:'',	
		start: moment(),
		end: moment(),
		seriesClasses:[]
	};

	addSeries(series) {
		api.post('series/', series).then(data => {
			this.props.history.push('/');
		}).catch(() => {
		});
	}

	deleteSeries(id) {
		api.delete('series/' + id).then(data => {
			this.props.history.push('/');
		});
	}

	updateSeries(id, series) {
		api.put('series/' + id, series).then(data => {
			this.props.history.push('/');
		});
	}


	saveSeries() {
		if (this.state.seriesId) {
			this.updateSeries(this.state.seriesId, this.state)			
		}
		else {
			this.addSeries(this.state);			
		}
	}

	render() {
		return (
			<div>
				<h2>Edit series</h2>
				<div>
					<div className="form-group">
						<label htmlFor="name">Name</label>
						<input type="text" className="form-control" id="name" value={this.state.name} onChange={(event) => this.setState({ name: event.target.value })} />
					</div>
					<div className="form-group">
						<label htmlFor="start">Start</label>
						<DatePicker id="start" className="form-control" selected={this.state.start} onChange={(date) => this.setState({ start: date })} locale="is" />
					</div>
					<div className="form-group">
						<label htmlFor="start">End</label>
						<DatePicker id="end" className="form-control" selected={this.state.end} onChange={(date) => this.setState({ end: date })} locale="is" />
					</div>

				</div>
				<div className="btn-group" >
					<Link className="btn btn-warning" to="/">Cancel</Link>					
					<button onClick={() => this.deleteSeries(this.state.seriesId)} className="btn btn-danger">Delete</button>
					<button onClick={this.saveSeries} className="btn btn-success">Save</button>
				</div>
			</div>
		)
	}
}
