import * as React from 'react';
import api from './ApiClient';
import moment from 'moment';
import Security from './Security';
import { Link } from "react-router-dom";
import { ButtonGroup } from 'react-bootstrap';


interface EventListProps {
	seriesId: string,
};


export default class EventList extends React.Component<EventListProps, any> {

	componentDidMount() {
		api.request('seriesevents/?seriesid=' + this.props.seriesId).then(data => {
			this.setState({ events: data });
		});

		api.request('series/' + this.props.seriesId).then(data => {
			console.log(data);
			this.setState({ series: data });
		});
	}

	state = {
		series: {
			name: '',
			start:null
		},
		events: []
	};

	render() {
		return (
			<div>
				<h2>{moment(this.state.series.start).format('YYYY')} - {this.state.series.name} - Events</h2>

				<table className="table">
					<thead>
						<tr>
							<th>Name</th>
							<th>Date</th>
							<th></th>
							{Security.isAdmin() && <th className="d-flex justify-content-end">Actions</th>}
						</tr>
					</thead>
					<tbody>
						
						{this.state.events.map(event => {
						return (
							<tr key={event.id}>
								<td><Link to={'/event/' + event.id}>{event.name}</Link></td>
								<td>{moment(event.date).format('YYYY-MM-DD')}</td>
								<td></td>
								{Security.isAdmin() &&

									<td className="d-flex justify-content-end">
										<div className="btn-group" >
											<Link className="btn btn-success" to={'/series/' + event.seriesId + '/events/edit/' + event.id}>Edit</Link>
										</div>
									</td>
								}
							</tr>
							)
						})}
						
					</tbody>
				</table>


				<ButtonGroup>
					<Link to="/" className="btn btn-success">Back</Link>
					{Security.isAdmin() &&
						<Link to={'/series/' + this.props.seriesId + '/events/add'} className="btn btn-success">Add</Link>
					}
				</ButtonGroup>
			</div>
		)
	}

}