import * as React from 'react';
import api from './ApiClient';
import { Button, ButtonToolbar, FormGroup, ControlLabel, FormControl, Modal } from 'react-bootstrap';


interface ImportRaceDataParams {
	raceId: string,
	match: any
}

interface ImportRaceDataState {
	file: any,
	messages: Array<string>,
	importing:any
}


export default class ImportRaceData extends React.Component<ImportRaceDataParams, ImportRaceDataState> {

	constructor(props) {
		super(props);

		this.startImport = this.startImport.bind(this);
		this.handleFileUpload = this.handleFileUpload.bind(this);		
	}

	componentWillMount() {
		this.setState({
			messages: [],
			importing: 0
		});
	}

	startImport() {
		var data = new FormData();
		data.append('file', this.state.file);

		this.setState({ importing: true });

		api.post('import/' + (this.props.raceId || this.props.match.params.raceid), data).then(response => {
			console.log(response);

			this.setState({
				messages: response,
				importing:false
			});

		});		
	}

	handleFileUpload(e) {

		if (e.target.files.length > 0) {
			this.setState({ file: e.target.files[0] });
		}
		else {
			this.setState({ file: null });
		}

	}

	render() {
		return (
			<div>							

				<Modal show={this.state.importing} animation={false} backdropClassName="show">
					<Modal.Header>
						<Modal.Title></Modal.Title>
					</Modal.Header>
					<Modal.Body>
						Importing....
					</Modal.Body>
					<Modal.Footer>
					</Modal.Footer>
				</Modal>

				<form>
					<FormGroup controlId="file">
						<ControlLabel>Database file</ControlLabel>
						<div>
							<FormControl type="file" onChange={this.handleFileUpload} />
						</div>
					</FormGroup>

					{(this.state.file) && 					
						<ButtonToolbar>
							<Button bsStyle="primary" onClick={this.startImport}>Import</Button>
						</ButtonToolbar>					
					}
				</form>

				<fieldset>
					
					<legend>Log</legend>			
					{
						this.state.messages.map(message =>
							<div key={message}>{message}</div>
						)
					}
					
					
				</fieldset>				

			</div>
		);
	}
}