import * as React from 'react';
import api from './ApiClient';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Link } from "react-router-dom";

export default class EditEvent extends React.Component<any, any> {

	constructor(props) {
		super(props);

		this.saveEvent = this.saveEvent.bind(this);
	}

	componentDidMount() {

		this.setState({ seriesId: this.props.match.params.seriesid });

		if (this.props.match.params.eventid) {
			this.loadRace(this.props.match.params.eventid);
		}


	}

	loadRace(id) {
		api.request('events/' + id).then(data => {
			this.setState({
				eventId:data.id,
				name: data.name,
				location: data.location,
				description: data.description,
				date: moment(data.date),
				registrationStart: moment(data.registrationStart),
				registrationEnd: moment(data.registrationEnd),
			});
		});
	}

	state = {
		eventId:null,
		seriesId: null,
		name: '',
		description: '',
		location: '',
		date: moment(),
		registrationEnd: moment(),
		registrationStart: moment()
	};

	addEvent(event) {
		api.post('events', event).then(data => {
			this.props.history.push('/series/' + this.state.seriesId);
		});
	}

	updateEvent(id, event) {
		api.put('events/' + id, event).then(data => {
			this.props.history.push('/series/' + this.state.seriesId);
		});
	}

	deleteEvent = (eventId) => {
		api.delete('events/' + eventId).then(data => {
			this.props.history.push('/series/' + this.state.seriesId);
		});
	}

	saveEvent(event) {
		if (this.state.eventId) {
			this.updateEvent(this.state.eventId, this.state)
		}
		else {
			this.addEvent(this.state);
		}
	}

	render() {
		return (
			<div>
				<h2>Edit event</h2>

				<div>
					<div className="form-group">
						<label htmlFor="name">Name</label>
						<input type="text" id="name" className="form-control" value={this.state.name} onChange={(event) => this.setState({ name: event.target.value })} />
					</div>
					<div className="form-group">
						<label htmlFor="name">Location</label>
						<input type="text" id="name" className="form-control" value={this.state.location} onChange={(event) => this.setState({ location: event.target.value })} />
					</div>
					<div className="form-group">
						<label htmlFor="name">Description</label>
						<input type="text" id="name" className="form-control" value={this.state.description} onChange={(event) => this.setState({ description: event.target.value })} />
					</div>

					<div className="form-group">
						<label htmlFor="start">Date</label>
						<DatePicker id="start" className="form-control" selected={this.state.date} onChange={(date) => this.setState({ date: date })} locale="is" />
					</div>
				</div>
				<div className="btn-group">
					<Link to={'/series/' + this.props.match.params.seriesid} className="btn btn-warning">Cancel</Link>
					<button onClick={this.saveEvent} className="btn btn-success">Save</button>
					{this.state.eventId && <button onClick={() => this.deleteEvent(this.state.eventId)} className="btn btn-danger">Delete</button>}
				</div>
			</div>
		)
	}

}