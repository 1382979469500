import * as React from 'react';

import EditCompetitors from './../Components/EditCompetitors';

export default class HomePage extends React.Component<any, any> {

	constructor(props: any) {	
		super(props);
	}

	
	render() {		
		return (			
			<div>
				<h2>Upcoming</h2>
				<EditCompetitors seriesId={this.props.match.params.raceid} />
			</div>
		)
	}
}
