import api from './ApiClient';
import * as axios from 'axios';


class Security {
	
	constructor(){
		this.addJwtHeader();

		this.validateToken();
	}

	private authenticatedUsername = "";

	private validateToken() {
		api.request('authentication/validatetoken').then(data => {
			this.authenticatedUsername = data;
			console.log(data);
		});
	}

	private addJwtHeader() {
		var jwtToken = window.localStorage.getItem('jwttoken');
		if (jwtToken) {
			axios.default.defaults.headers.common['Authorization'] = 'Bearer ' + jwtToken;
		}
		else {
			axios.default.defaults.headers.common['Authorization'] = null;
		}
	}

	public setToken(token: string) {
		window.localStorage.setItem('jwttoken', token);
		this.addJwtHeader();
	}

	public login(username: string, password: string, success: () => void, error : () => void) {
		api.post('authentication/createtoken', { username: username, password: password }).then(data => {
			this.setToken(data.token);
			this.authenticatedUsername = data.username;
			if (success) success();
		}).catch(() => {
			this.logout();
			if (error) error();
		});
	}

	public logout() {
		window.localStorage.setItem('jwttoken', null);
		this.addJwtHeader();
		this.authenticatedUsername = "";
	}


	public isAdmin = () => {
		return this.authenticatedUsername != "";
	}

}

export default new Security();