import * as React from 'react';
import api from './ApiClient';

interface CheckpointComponentProps {
	seriesId: string,
	value: string,
	onChange: (seriesClass: any) => void,	
};

export default class ClassDropDown extends React.Component<CheckpointComponentProps, any> {

	constructor(props, context) {
		super(props);

		this.changeClassId = this.changeClassId.bind(this);
	}

	componentDidMount() {
		if (this.props.seriesId) {
			api.request('seriesclasses/?seriesId=' + this.props.seriesId).then(data => {
				this.setState({ seriesClasses: data });

				if (!this.props.value) {
					var firstClass = data[0].id;
					this.setState({ classId: firstClass });
					this.props.onChange(this.state.seriesClasses.find((e) => e.id == firstClass));
				}
			});
		}

		this.setState({ classId: this.props.value });

	}

	componentWillReceiveProps(nextProps) {

		if (nextProps.seriesId != this.props.seriesId) {
		api.request('seriesclasses/?seriesId=' + nextProps.seriesId).then(data => {
			this.setState({ seriesClasses: data });
			});
		}

		this.setState({ classId: nextProps.value });
	}

	changeClassId = (event) => {

		var classId = event.target.value;
		
		this.setState({ classId: classId });

				
		this.props.onChange(this.state.seriesClasses.find((e) => e.id == classId));
	}

	state = {
		seriesClasses: [],
		classId:''
	};

	render() {
		return (			
			<select name="class" value={this.state.classId} onChange={this.changeClassId} className="form-control">
				{this.state.seriesClasses.map((sc, index) => <option value={sc.id} key={sc.id}>{sc.name}</option>)}
			</select>			
		)
	}

}