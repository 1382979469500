import * as React from 'react';
import api from './ApiClient';
import moment from 'moment';
import { Button, ButtonGroup } from 'react-bootstrap';
import { Link } from "react-router-dom";

export default class RaceSummary extends React.Component<any, any> {

	componentDidMount() {
		api.request('races/racesummary/?onlyUnfinished=true').then(data => {
			this.setState({ races: data });
		});
	}

	state = {
		races: [],
	};

	render() {
		return (
			<div>

				<table className="table">
					<thead>
						<tr>
							<th>Date</th>
							<th></th>
							<th>Series</th>
							<th>Event</th>
							<th>Race</th>
						</tr>
					</thead>
					<tbody>

						{this.state.races.map(race => {
							return (
								<tr key={race.raceId}>
									<td>{moment(race.start).format('YYYY-MM-DD')}</td>									
									<td>{moment(race.start).fromNow()}</td>
									<td>{race.seriesName}</td>
									<td>{race.eventName}</td>
									<td><Link to={'/race/' + race.raceId}>{race.raceName}</Link></td>
								</tr>
							)
						})}

					</tbody>
				</table>

			</div>
		)
	}
}