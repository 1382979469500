import * as React from 'react';
import Security from './Security';

export default class LoginForm extends React.Component<any, any> {

	componentDidMount() {
		this.onLogin = this.onLogin.bind(this);
	}

	onLogin = (e) => {
		console.log('login click');
		Security.login(this.state.username, this.state.password, () => {
			this.props.history.push('/');
		}, () => {
			this.setState({ message: 'Login failed' });
		});
	}

	state = {
		username:'',
		password: '',
		message: ''
	};

	render() {
		return (
			<div>

				<h1>Login</h1>


				<div className="panel panel-warning">{this.state.message}</div>

				<div>

					<label htmlFor="username">Username</label>					
					<input id="username" type="text" value={this.state.username} onChange={(e) => this.setState({ username: e.target.value })} />
				</div>

				<div>
					<label htmlFor="password">Password</label>
					<input id="password"  type="password" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} />
				</div>

				<div>
					<button onClick={this.onLogin}>Login</button>
				</div>

			</div>
		)
	}

}