import * as React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { Link } from "react-router-dom";
import api from '../Components/ApiClient';

import RaceCompetitorsList from '../Components/RaceCompetitorsList'



export default class RaceCompetitorsPage extends React.Component<any, any> {

	constructor(props: any) {
	
		super(props);
	}


	async removeAllCompetitors() {

		if (confirm('Are you sure you remove all competitors from race?')) {
			var result = await api.delete('Competitors/RemoveAll/' + this.props.match.params.raceid);
		}
	}

	state = {
		//raceId: ''
	};
	
	render() {		
		return (
			<div>

				<div>
					<h2>Competitors</h2>					
				</div>

				<ButtonGroup>
					<Link to={'/race/' + this.props.match.params.raceid + '/addCompetitor'} className="btn btn-primary">Add</Link>
					<Link className="btn btn-primary" to={'/ImportCompetitors/' + this.props.match.params.raceid}>Import Competitors</Link>					
					<a href={'/api/Competitors/InRace/csv?raceid=' + this.props.match.params.raceid} className="btn btn-primary" target="_blank">CSV</a>
					<Button bsStyle="warning" onClick={() => this.removeAllCompetitors()}>Remove all</Button>
				</ButtonGroup>

				<div>
					<RaceCompetitorsList raceId={this.props.match.params.raceid} />
				</div>
			</div>
		)
	}
}