import * as React from 'react';
import api from './ApiClient';
import moment from 'moment';
import ClassDropDown from './ClassDropDown';
import { Button, ButtonGroup } from 'react-bootstrap';

interface EditCompetitorProps {
	competitorId: String,
	race: any,
	onSave: (competitor: any) => void,
	onCancel: () => void
};

export default class EditCompetitor extends React.Component<EditCompetitorProps, any> {
	constructor(props, context) {
		super(props);

		this.saveCompetitor = this.saveCompetitor.bind(this);
		this.cancel = this.cancel.bind(this);
	}

	componentDidMount() {

		if (this.props.competitorId) {
			var promise = api.request(`competitors/competitor/race/${this.props.race.id}/${this.props.competitorId}`).then(data => {
				this.setState(data);
			});
		}
		else {
			console.log('here', this.props);
			/*
			var data = api.request(`races/${this.props.race}`).then(data => {
				console.log('race', data);
				this.setState({ race: data });
			});			
			*/
		}
	}

	state = {
		id: '',
		name: '',
		ssn: '',
		age: 0,
		phone: '',
		email: '',
		raceId: '',
		transponder: '',
		number: 0,
		group: '',
		seriesId: '',
		seriesClassId: '',
		dnf: false,
		seriesPoints: null
	}

	saveCompetitor() {
		var competitor = {
			name: this.state.name,
			ssn: this.state.ssn,
			age: this.state.age,
			phone: this.state.phone,
			email: this.state.email,
			raceId: this.props.race.id,
			transponder: this.state.transponder,
			number: this.state.number,
			seriesId: this.props.race.seriesId,
			seriesClassId: this.state.seriesClassId,
			dnf: this.state.dnf,
			seriesPoints: this.state.seriesPoints
		}

		console.log('saving', competitor);

		if (this.props.competitorId) {
			var promise = api.put('competitors/competitor/' + this.state.id, competitor).then(data => {
				this.props.onSave(this.state);
			});
		} else {
			var promise = api.post('competitors/competitor/' + this.state.id, competitor).then(data => {
				this.props.onSave(this.state);
			});
		}

	}

	cancel() {
		this.props.onCancel();
	}


	render() {
		return (
			<div>
				<div>
					<div className="form-group">
						<label htmlFor="name">Name</label>
						<input type="text" id="name" className="form-control" value={this.state.name} onChange={(event) => this.setState({ name: event.target.value })} />
					</div>

					<div className="form-group">
						<label htmlFor="number">Number</label>
						<input type="number" id="number" className="form-control" value={this.state.number} onChange={(event) => { if (!isNaN(Number(event.target.value))) this.setState({ number: Number(event.target.value) }) }} />
					</div>

					<div className="form-group">
						<label htmlFor="name">SSN</label>
						<input type="text" id="ssn" maxLength={10} className="form-control" value={this.state.ssn} onChange={(event) => this.setState({ ssn: event.target.value })} />
					</div>

					<div className="form-group">
						<label htmlFor="name">Age</label>
						<input type="number" id="age" className="form-control" value={this.state.age !== null ? this.state.age : ''} onChange={(event) => this.setState({ age: event.target.value == '' ? null : parseInt(event.target.value) })} />
					</div>

					<div className="form-group">
						<label htmlFor="name">Phone</label>
						<input type="text" id="phone" className="form-control" value={this.state.phone} onChange={(event) => this.setState({ phone: event.target.value })} />
					</div>

					<div className="form-group">
						<label htmlFor="name">Email</label>
						<input type="text" id="email" className="form-control" value={this.state.email} onChange={(event) => this.setState({ email: event.target.value })} />
					</div>

					<div className="card card-default">						
						<div className="card-body">

							<div className="card-title"><h4>Race data</h4></div>

							<div className="form-group">
								<label htmlFor="Transponder">Transponder</label>
								<input type="text" id="Transponder" className="form-control" value={this.state.transponder} onChange={(event) => this.setState({ transponder: event.target.value })} />
							</div>

							<div className="form-group">
								<label htmlFor="group">Group</label>
								<ClassDropDown seriesId={this.props.race.seriesId} value={this.state.seriesClassId} onChange={(seriesClass) => this.setState({ seriesClassId: seriesClass.id, group: seriesClass.name })} />
							</div>

							<div className="form-group">
								<label htmlFor="dnf">DNF</label>
								<input type="checkbox" id="dnf" className="form-control" checked={this.state.dnf} onChange={(event) => this.setState({ dnf: event.target.checked })} />
							</div>

							<div className="form-group">
								<label htmlFor="dnf">Series points</label>
								<input type="number" id="seriesPoints" className="form-control" value={this.state.seriesPoints !== null ? this.state.seriesPoints : ''} onChange={(event) => this.setState({ seriesPoints: event.target.value == '' ? null : parseInt(event.target.value) })} />
							</div>
						</div>
					</div>
				</div>

				<ButtonGroup>
					<Button bsStyle="primary" onClick={this.saveCompetitor}>Save</Button>
					<Button bsStyle="primary" onClick={this.cancel}> Cancel</Button>
				</ButtonGroup >

			</div>
		)
	}

}