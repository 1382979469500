import * as React from 'react';
import api from './ApiClient';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ClassSelecetor from './ClassSelector';

interface EditCompetitorsProps {
	seriesId: string,
};

export default class EditCompetitors extends React.Component<EditCompetitorsProps, any> {


	constructor(props,context) {
		super(props);
	}

	componentDidMount() {

		var racePromise = api.request('series/' + this.props.seriesId).then(data => {
			this.setState({ series: data });
		});

		var competitorsPromise = api.request('competitors/inseries?seriesid=' + this.props.seriesId).then(data => {
			this.setState({				
				competitors: data.reduce((map, competitor) => (map[competitor.number] = competitor, map), {})
			});
		});
	}

	state = {
		series: null,
        competitors: [],
        classId:null
	}

	processInput = (input:string) => {
		var linePattern = /^([0-9]+)\t([^\t\n]+)(.*)/mig;

		var match;

		let competitors = Object.assign({}, this.state.competitors);

		while (match = linePattern.exec(input)) {
			var competitor = competitors[match[1]];
			if (competitor) {
				competitor.newName = match[2];
			}


			//this.setState((state) => { competitors: {...state.competitors, name:match[2];}})

			console.log(competitor);
			//console.log(this.state.competitors[match[1]]);
			//console.log(match[1], match[2]);
		}

		this.setState({ competitors });

    }

    classChanged = (classId) => {
        this.setState({ classId: classId });
    }

	render() {
		return (
            <div>
                <ClassSelecetor seriesId={this.props.seriesId} onChange={this.classChanged} showAllButton={true} />

				<textarea onChange={(e) => this.processInput(e.target.value)}>

				</textarea>
				<table>
					<thead>
						<tr>
							<th>Number</th>
							<th>Name</th>
						</tr>
					</thead>
                    <tbody>
                        {Object.keys(this.state.competitors).filter(key => !this.state.classId || this.state.competitors[key].seriesClassId == this.state.classId).map((key, index) => 
                            <tr>
                                <td>{key}</td>
                                <td className={this.state.competitors[key].newName ? 'text-primary' : ''}>{this.state.competitors[key].newName || this.state.competitors[key].name}</td>
                            </tr>                                
					    )}
					</tbody>
				</table>
			</div>
		)
	}

}