import * as React from 'react';
import api from './ApiClient';

interface CheckpointComponentProps {
	seriesId: string,
	onChange: (classid: string) => void
	showAllButton?: boolean
};

export default class ClassSelecetor extends React.Component<CheckpointComponentProps, any> {

	componentDidMount() {
		if (this.props.seriesId) {
			api.request('seriesclasses/?seriesId=' + this.props.seriesId).then(data => {
				this.setState({ seriesClasses: data });

				//* this is stupid but it works for now!
				if (!this.props.showAllButton && data.length != 0) {
					this.changeClassId(data[0].id);
				}				
			});
		}
	}

	componentWillReceiveProps(nextProps) {

		if (nextProps.seriesId != this.props.seriesId) {
		api.request('seriesclasses/?seriesId=' + nextProps.seriesId).then(data => {
			this.setState({ seriesClasses: data });
				//* same stupidness again
				if (!this.props.showAllButton) {
					this.changeClassId(data[0].id);
				}
			});
		}
	}

	changeClassId = (classId) => {
		this.setState({ classId: classId });
		this.props.onChange(classId);
	}

	state = {
		seriesClasses: [],
		classId: ''
	};

	render() {
		return (			
			<div className="btn-group" style={{display:'block'}}>
				{this.props.showAllButton &&
					<button onClick={(e) => this.changeClassId('')} className={(this.state.classId == '') ? 'btn btn-primary' :'btn btn-secondary'}>All</button>
				}
				{this.state.seriesClasses.map(sc => <button className={(this.state.classId == sc.id) ? 'btn btn-primary' : 'btn btn-secondary'} key={sc.id} onClick={(e) => this.changeClassId(sc.id)} >{sc.name}</button>)}

			</div>			
		)
	}

}