import * as React from 'react';
import EventList from './../Components/EventList'
import SeriesPointsTable from './../Components/SeriesPointsTable'
import ClassSelector from './../Components/ClassSelector'




export default class SeriesPage extends React.Component<any, any> {

	constructor(props: any) {
	
		super(props);
	}

	state = {
		classId: ''
	};

	changeClass = (classId) => {
		this.setState({ classId: classId });
	}

	render() {		
		return (
			<div>
				<div>
					<h2>Series</h2>
					<EventList seriesId={this.props.match.params.seriesid} />
				</div>

				<div>
					<h2>Results</h2>
					<ClassSelector seriesId={this.props.match.params.seriesid} onChange={this.changeClass} showAllButton={true}></ClassSelector>

					<SeriesPointsTable seriesId={this.props.match.params.seriesid} classId={this.state.classId} />
				</div>
			</div>
		)
	}
}
