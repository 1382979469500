import * as React from 'react';
import api from './ApiClient';
import DatePicker from 'react-datepicker';
import moment from 'moment'; 
import { Link } from "react-router-dom";
import { Button, ButtonGroup } from 'react-bootstrap';

var styles = require('react-datepicker/dist/react-datepicker.css');

export default class EditSeriesClasses extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.deleteClass = this.deleteClass.bind(this);
	}

	componentDidMount() {
		if (this.props.match.params.seriesid) {
			this.loadClasses(this.props.match.params.seriesid);
		}
	}

	loadClasses(id) {
		api.request('seriesclasses/?seriesId=' + id).then(data => {
			this.setState({ seriesClasses: data });			
		});
	}

	deleteClass(seriesClass) {
		if (confirm('Are you sure you want to delete class ' + seriesClass.name)) {
			api.delete('seriesclasses/class/' + seriesClass.id).then(data => {
		 		this.loadClasses(this.props.match.params.seriesid);
			});
		}
	}

	state = {
		seriesClasses:[]
	};


	render() {
		return (
			<div>
				<div>
					<h2>Classes</h2>
					<table>
						<tr>
							<th>Name</th>
							<th>Actions</th>
						</tr>
						{this.state.seriesClasses.map(sc =>
							<tr key={sc.id} >
								<td>{sc.name}</td>
								<td>
									<ButtonGroup>
										<Button className="btn btn-warning" onClick={() => this.deleteClass(sc)}>Delete</Button>
									</ButtonGroup>
								</td>
							</tr>
						)}
					</table>
				</div>
			</div>
		)
	}
}
