import * as React from 'react';
import api from './ApiClient';
import { Button, ButtonToolbar, FormGroup, ControlLabel, FormControl, Modal, Form } from 'react-bootstrap';
import moment from 'moment';



interface ImportCompetitorsParams {
	match: any
}

export default class ImportCompetitors extends React.Component<ImportCompetitorsParams, any> {

	constructor(props) {
		super(props);

		//this.startImport = this.startImport.bind(this);
		//this.importChanged = this.importChanged.bind(this);
		this.parse = this.parse.bind(this);
		this.sortRows = this.sortRows.bind(this);
	}

	componentWillMount() {
		var raceId = this.props.match.params.raceid;
		this.getRace(raceId)
	}

	stringOrder = (a: string, b: string) => (a < b) ? -1 : (a > b) ? 1 : 0;

	seriesPoints = {};

	/*
	getCheckpoints(raceId) {
		console.log('mmmxx');
		return api.request('checkpoints/?raceid=' + raceId).then(data => {
			data.sort((a, b) => this.stringOrder(a.timestamp, b.timestamp));
			this.setState({ checkpoints: data });
		});
	}
	*/

	getRace(raceId) {
		api.request('races/' + raceId).then(data => {
			this.setState({
				race: data
			});

			this.getCompetitorsInSeries(data.seriesId);
			this.getClasses(data.seriesId);
		});
	}

	getClasses(seriesId) {
		api.request('SeriesClasses?seriesId=' + seriesId).then(data => {
			this.setState({
				classes: data
			});
		});
	}

	getCompetitorsInSeries(seriesId) {
		var competitorsPromise = api.request('competitors/inseries?seriesid=' + seriesId).then(data => {

			this.setState({
				competitorsByNumber: data.reduce((map, obj) => (map[obj.number] = obj, map), {}),
				competitors: data
			});
		});
	}

	getSeriesPointsPromise(seriesId, classId) {
		var key = seriesId + ':' + classId;

		if (!this.seriesPoints[key]) {
			var apiPromise = api.request('results/SeriesResult?seriesId=' + seriesId + '&classid=' + classId);

			var promise = apiPromise.then(data => {
				return data.reduce((map, obj) => (map[obj.competitorId] = obj.totalPoints, map), {});
			});
			this.seriesPoints[key] = promise;
			return promise;
		}
		else {
			return this.seriesPoints[key];
		}
	}

	getCompetitorSeriesPoints(classId) {


		this.getSeriesPointsPromise(this.state.race.seriesId, classId).then(points => {

			var competitors = { ...this.state.competitorsByNumber };
			var numbers = Object.keys(competitors);

			numbers.forEach((number, index) => {
				if (points[competitors[number].id]) {
					competitors[number].points = points[competitors[number].id];
				}
			});

			this.setState({ competitorsByNumber: competitors });

		});
	}

	getExistingCompetitor(number) {
		var getExistingCompetitor = this.state.competitorsByNumber[number];
		if (getExistingCompetitor) return getExistingCompetitor;
		else return '';
	}



	/*
	getExistingCompetitorName(number) {
		var getExistingCompetitor = this.state.competitorsByNumber[number];
		if (getExistingCompetitor) return getExistingCompetitor.name;
		else return '';
	}
	*/


	state = {
		raceId: '',
		importData: '',
		rowFormat: '',
		rows: [],
		race: null,
		classes: [],
		competitors: [],
		competitorsByNumber: [],
		columnNames: [],
		classMap: {},
		columnMap: {
			name: 0,
			number: 6,
			//club: 2,
			phone: 5,
			ssn: 1,
			group: 3,
			transponder: 7
		}

	}

	mapClass(name, id) {
		var classMap = { ...this.state.classMap };
		classMap[name] = id;
		this.setState({ classMap: classMap }, () => {
			this.getCompetitorSeriesPoints(id);
		});
	}

	generateTransponderNumbers() {

		var transponderNumber = 0;

		var rows = this.state.rows.map(row => {
			if (row.transponderGenerateStart) transponderNumber = row.transponderGenerateStart;
			if (transponderNumber) {
				row.transponder = transponderNumber;
				transponderNumber++;
			}
			else
			{
				row.transponder = null;
			}


			return row;
		})


		this.setState({ rows: rows });


	}

	setTransponder(rowNumber, transponderNumber) {
		var rows = [...this.state.rows];

		rows[rowNumber]['transponderGenerateStart'] = transponderNumber;
			
		this.setState({rows:rows}, () => {
			this.generateTransponderNumbers();
		});
		
	}

	setRemoveRow(index) {

		this.setState(state => {
			state.rows.splice(index, 1);
			return { rows: state.rows };
		});

		//console.log(this.state.rows)
	}

	async importAll() {
		for (var i = 0; i < this.state.rows.length; i++) {
			var rowStatus = this.state.rows[i].status;
			if (rowStatus != 'updated' && rowStatus != 'imported') {
				await this.importRow(i);
			}
		}
	}

	async importRow(index) {
		let rows = [...this.state.rows];
		var row = { ...rows[index] };

		var classId = this.state.classMap[row.values[this.state.columnMap.group]];

		if (!classId) {
			row.status = 'Class is required';
			rows[index] = row;
			this.setState({ rows });
			return;
		}

		if (isNaN(parseInt(row.values[this.state.columnMap.number]))) {
			row.status = 'Number is required';
			rows[index] = row;
			this.setState({ rows });
			return;
		}

		if (classId == '+') {

			var seriesClass = {
				name: row.values[this.state.columnMap.group],
				seriesId: this.state.race.seriesId,
			}

			var newSeriesClass = await api.post('SeriesClasses/class', seriesClass);
			this.state.classes.push(newSeriesClass);

			this.mapClass(row.values[this.state.columnMap.group], newSeriesClass.id);

			classId = newSeriesClass.id;
		}

		var competitor = {
			id:'00000000-0000-0000-0000-000000000000',
			name: row.values[this.state.columnMap.name],
			number: parseInt(row.values[this.state.columnMap.number]),
			phone: row.values[this.state.columnMap.phone],
			ssn: row.values[this.state.columnMap.ssn],
			age: this.getAge(row.values[this.state.columnMap.ssn]),
			transponder: (this.state.columnMap.transponder == 1337 && row.transponder) ? row.transponder.toString() : row.values[this.state.columnMap.transponder],
			raceId: this.state.race.id,
			seriesClassId: classId,
			dnf: false,
			seriesPoints: null
		}

		if (!competitor.name) {
			row.status = 'Name is required';
			rows[index] = row;
			this.setState({ rows });
			return;
		}

		if (isNaN(competitor.number)) {
			rows[index] = row;
			this.setState({ rows });
			row.status = 'Number is required';
			return;
		}

		if (!competitor.transponder) {
			rows[index] = row;
			this.setState({ rows });
			row.status = 'Transponder is required';
			return;
		}

		var existingCompetitor = this.getExistingCompetitor(row.values[this.state.columnMap.number]);

		if (existingCompetitor) {
			try {
				competitor.id = existingCompetitor.id;
				var newSeriesClass = await api.put('competitors/competitor/' + existingCompetitor.id, competitor, true);
				row.status = 'updated';
			} catch (e) {
				row.status = 'ERROR: ' + e.response.data;
			}
		}
		else {
			var newSeriesClass = await api.post('competitors/competitor', competitor, true);
			row.status = 'imported';
		}

		rows[index] = row;
		this.setState({ rows });
	}

	getAge(ssn) {
		let ssnPattern = /^(\d{2})(\d{2})(\d{2})[^\d]?(\d{3})(\d{1})$/;

		var date = new Date();
		var match = ssn.match(ssnPattern);
		if (match != null) {
			var [ssn, ssnDay, ssnMonth, ssnYear, ssnCheck, ssnCentury] = ssn.match(ssnPattern);
			var year = 2000;
			if (Number(ssnCentury) == 9) year = 1900;
			year += Number(ssnYear);

			return date.getFullYear() - year;
		}

		return null;
	}

	sortRows() {
		
		var classCompetitorCount = this.state.rows.reduce((result, row) => {
			var c = this.state.classMap[row.values[this.state.columnMap.group]];
			if (c) {
				result[c] = (result[c] || 0) + 1;
			}
			return result;
		}, {});


		var sorted = this.state.rows.sort((a, b) => {

			var aClass = this.state.classMap[a.values[this.state.columnMap.group]];
			var bClass = this.state.classMap[b.values[this.state.columnMap.group]];

			if (aClass != bClass) {
				return ((classCompetitorCount[aClass] || 0) - (classCompetitorCount[bClass] || 0)) * -1;
			}

			var aPoints = this.getExistingCompetitor(a.values[this.state.columnMap.number]).points | 0;
			var bPoints = this.getExistingCompetitor(b.values[this.state.columnMap.number]).points | 0;

			return (aPoints - bPoints) * -1;			
		});

		this.setState({ rows: sorted });
	}


	parse() {
		var rowPattern = /^(.*)$/mig;		
		var cleanValuePattern = /^"?(.*?)"?$/

		var columnNames = [];
		
		var rows = [];

		var lines = this.state.importData.match(rowPattern);
		for (let i = 0; i < lines.length; i++) {
			let line = lines[i];

			if (line.trim() != '') {

				var row = {
					status: '',
					values: []
				};

				for (var value of line.split(/[,\t]/)) {
					var cleanValue = cleanValuePattern.exec(value)[1];
					if (i == 0) {
						columnNames.push(cleanValue);
					}
					else {
						row.values.push(cleanValue);
					}
				}

				if (i == 0) this.setState({ columnNames: columnNames });
				else rows.push(row);
			}
		}

		columnNames.push('**Unmapped**');
		
		this.setState({ rows: rows });
	}

	render() {
		return (
			<div>
				<textarea value={this.state.importData} onChange={(event) => this.setState({ importData: event.target.value })} />

				<Button onClick={this.parse}>Parse</Button>
				<Button onClick={this.sortRows}>Sort</Button>


				<table className="table table-striped">
					<thead>
						<tr>
							<th>								
								Number:
								<select
									value={this.state.columnMap.number}
									onChange={(event) => this.setState({ columnMap: { ...this.state.columnMap, number: event.target.value } })}
									className="form-control">
									{this.state.columnNames.map((column,index) =>
										<option value={index} key={column}>{column}</option>
									)}
								</select>								
							</th>
							<th>
								Name:
								<select
									value={this.state.columnMap.name}
									onChange={(event) => this.setState({ columnMap: { ...this.state.columnMap, name: event.target.value } })} 
									className="form-control">
									{this.state.columnNames.map((column, index) =>
										<option value={index} key={column}>{column}</option>
								)}
								</select>
							</th>
							<th>
								SSN:
								<select
									value={this.state.columnMap.ssn}
									onChange={(event) => this.setState({ columnMap: { ...this.state.columnMap, ssn: event.target.value } })} 
									className="form-control">
									{this.state.columnNames.map((column, index) =>
										<option value={index} key={column}>{column}</option>
									)}
								</select>								
							</th>

							<th colSpan={2}>
								Group:
								<select
									value={this.state.columnMap.group}
									onChange={(event) => this.setState({ columnMap: { ...this.state.columnMap, group: event.target.value } })} 
									className="form-control">
									{this.state.columnNames.map((column, index) =>
										<option value={index} key={column}>{column}</option>
									)}
								</select>
							</th>
							{/*
							<th>
								Club:
								<select
									value={this.state.columnMap.club}
									onChange={(event) => this.setState({ columnMap: { ...this.state.columnMap, club: event.target.value } })}
									className="form-control">
									{this.state.columnNames.map((column, index) =>
										<option value={index} key={column}>{column}</option>
									)}
								</select>
							</th>
							*/}
							<th>
								Phone:
								<select
									value={this.state.columnMap.phone}
									onChange={(event) => this.setState({ columnMap: { ...this.state.columnMap, phone: event.target.value } })}
									className="form-control">
									{this.state.columnNames.map((column, index) =>
										<option value={index} key={column}>{column}</option>
									)}
								</select>
							</th>
							<th>
								Transponder:
								<select
									value={this.state.columnMap.transponder}
									onChange={(event) => this.setState({ columnMap: { ...this.state.columnMap, transponder: event.target.value } })}
									className="form-control">

									<option value="1337">** Generate **</option>

									{this.state.columnNames.map((column, index) =>
										<option value={index} key={column}>{column}</option>
									)}
								</select>
							</th>
							<th>
								Existing data
							</th>
							<th>
								Actions
							</th>
							<th>
								Status
							</th>
						</tr>
					</thead>
					<tbody>
						{this.state.rows.map((row, i) =>
							<tr key={i} >
								<td>
									{row.values[this.state.columnMap.number]}
								</td>
								<td>
									{row.values[this.state.columnMap.name]}															
								</td>
								<td>
									{row.values[this.state.columnMap.ssn]}

									<span> ({this.getAge(row.values[this.state.columnMap.ssn])}) </span>
								</td>
								<td>{row.values[this.state.columnMap.group]}</td>
								<td>
									<select value={this.state.classMap[row.values[this.state.columnMap.group]]} onChange={(event) => this.mapClass(row.values[this.state.columnMap.group], event.target.value)}>
										<option value="">--Map Class--</option>
										{this.state.classes.map(seriesClass =>
											<option key={seriesClass.id} value={seriesClass.id}>{seriesClass.name}</option>
										)}
										<option value="+">**Create**</option>
									</select>
								</td>
								{/*<td>{row[this.state.columnMap.club]}</td>*/}
								<td>{row.values[this.state.columnMap.phone]}</td>
								<td>
									{(this.state.columnMap.transponder == 1337) && <div>
										<input type="number" onChange={(event) => this.setTransponder(i, event.target.value)} maxLength={4} size={ 4} ></input>
										{row.transponder }
									</div>}
									{row.values[this.state.columnMap.transponder]}
								</td>
								<td>
									&nbsp;

									{this.getExistingCompetitor(row.values[this.state.columnMap.number]) &&
										<span>
											{this.getExistingCompetitor(row.values[this.state.columnMap.number]).name}

											(
												{this.getExistingCompetitor(row.values[this.state.columnMap.number]).points}
											)
										</span>
									}

								</td>
								<td>
									<Button className="btn btn-warning" onClick={() => this.setRemoveRow(i)}>Remove</Button>
									<Button className="btn btn-successs" onClick={() => this.importRow(i)}>Import</Button>
								</td>
								<td>
									{row.status}
								</td>
							</tr>
						)}
					</tbody>
				</table>


				<Button className="btn btn-primary" onClick={() => this.importAll()}>Import All</Button>

			</div>
		);
	}
}