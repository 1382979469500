import * as React from 'react';
import api from './ApiClient';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Link } from "react-router-dom";

export default class EditRace extends React.Component<any, any> {


	constructor(props,context) {
		super(props);

		this.saveRace = this.saveRace.bind(this);

	}

	componentDidMount() {

		this.setState({ eventid: this.props.match.params.eventid });

		if (this.props.match.params.raceid) {
			this.loadRace(this.props.match.params.raceid);
		}
	}

	loadRace(id) {
		api.request('races/' + id).then(data => {
			this.setState({
				eventid: data.eventId,
				seriesid: data.seriesId,
				name: data.name,
				start: moment(data.start),
				duration: data.duration,
				finished: data.finished,
				pointsSystem: data.pointsSystem,
				rules: data.rules
			});
		});
	}

	state = {
		eventid: '',
		seriesid: '',
		name: '',
		start: moment(),
		duration: 0,
		finished: false,
		pointsSystem: '',
		rules:''		
	}

	addRace(race) {
		api.post('races', {
			eventid: race.eventid,
			seriesid: race.seriesId,
			name: race.name,
			start: race.start,
			duration: race.duration,
			finished: race.finished,
			pointsSystem: race.pointsSystem,
			rules: race.rules
		}).then(data => {
			this.props.history.push('/event/' + this.state.eventid);
		});
	}

	updateRace(id, race) {
		api.put('races/' + id, race).then(response => {
			this.props.history.push('/event/' + this.state.eventid);
		});
	}

	deleteRace(raceId) {
		api.delete('races/' + raceId).then(response => {
			this.props.history.push('/event/' + this.state.eventid);
		});
	}


	saveRace(event) {
		if (this.props.match.params.raceid) {
			this.updateRace(this.props.match.params.raceid, this.state)
		}
		else {
			this.addRace(this.state);
		}
	}

	removeRule(ruleName) {
		var rules = (this.state.rules != '') ? this.state.rules.split(',') : new Array();
		var index = rules.indexOf(ruleName);
		if (index != -1) {
			rules.splice(index, 1);
			this.setState({ rules: rules.join(',') });		
		}
		console.log(this.state.rules);
	}

	addRule(ruleName) {
		var rules = (this.state.rules != '')?this.state.rules.split(','):new Array();		
		var index = rules.indexOf(ruleName,0);	
		if (index == -1) {
			rules.push(ruleName);
			this.setState({ rules: rules.join(',') });			
		}				
	}
	

	render() {
		return (
			<div>
				<div className="form">
					<div className="form-group">
						<label htmlFor="name">Name</label>
						<input type="text" id="name" className="form-control" value={this.state.name} onChange={(event) => this.setState({ name: event.target.value })} />
					</div>

					<div className="form-group">
						<label htmlFor="start">Date</label>
						<DatePicker id="start" className="form-control" selected={this.state.start} onChange={(date) => this.setState({ start: date })} locale="is" />
					</div>

					<div className="form-group">
						<label htmlFor="start">Start: {moment(this.state.start).format('MM. DD. YYYY')} {this.state.start.format("HH:mm")}</label>

						<input type="time" id="start" className="form-control" value={this.state.start.format("HH:mm")} onChange={(event) => {
							var time = event.target.value.split(':');
							this.setState({ start: this.state.start.set('hour', Number(time[0])).set('minute', Number(time[1])) });
						}}></input>
					</div>

					<div className="form-group">
						<label htmlFor="duration">Duration</label>
						<input type="number" id="duration" className="form-control" value={this.state.duration} onChange={(event) => this.setState({ duration: parseInt(event.target.value) })}></input>
					</div>
					
					<div className="form-group">						
						<input type="checkbox" id="finished" className="" checked={this.state.finished} onChange={(event) => this.setState({ finished: event.target.checked })}></input>						
						<label htmlFor="finished" className="checkbox-inline">Finished</label>
					</div>

					<div className="form-group">						
						<label htmlFor="pointsSystem" className="checkbox-inline">Points system</label>

						<select value={this.state.pointsSystem} id="pointsSystem" onChange={(event) => this.setState({ pointsSystem: event.target.value })}>
							<option value="" >Default(MSI)</option>
							<option value="MSI" >MSI</option>
							<option value="Wess">Wess</option>
						</select>						
					</div>

					<div className="form-group">
						<input type="checkbox" id="50pLaps" checked={this.state.rules.split(',').indexOf('50pLaps') != -1} onChange={(event) => event.target.checked ? this.addRule(event.target.id) : this.removeRule(event.target.id)} /><label htmlFor="50pLaps">50% Laps Rule</label>
					</div>

					<div className="form-group">
						<input type="checkbox" id="Min5Competitors" checked={this.state.rules.split(',').indexOf('Min5Competitors') != -1} onChange={(event) => event.target.checked ? this.addRule('Min5Competitors') : this.removeRule(event.target.id)} /><label htmlFor="Min5Competitors">Minimum 5 competitor Rule</label>
					</div>


				</div>
				<div className="btn-group">
					<Link to={'/event/' + this.state.eventid } className="btn btn-warning">Cancel</Link>
					<button onClick={this.saveRace} className="btn btn-success">Save</button>
					{this.props.match.params.raceid &&
						<button onClick={(e) => this.deleteRace(this.props.match.params.raceid)} className="btn btn-danger">Delete</button>
					}
				</div>
			</div>
		)
	}

}