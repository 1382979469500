import * as React from 'react';
import api from './ApiClient';


interface SeriesPointsTableProps {

	seriesId: any,
	classId: any,
};

export default class SeriesPointsTable extends React.Component<SeriesPointsTableProps, any> {

	componentDidMount() {

		api.request(`results/SeriesResult?seriesId=${this.props.seriesId}&classid=${this.props.classId}`).then(data => {
			this.setState({ results: data });				
		});


		api.request('races/series/' + this.props.seriesId).then(data => {
			this.setState({ races: data });
		});



		api.request('seriesclasses/?seriesId=' + this.props.seriesId).then(data => {
			//this.setState({ seriesClasses: data });
		
			var classes = data.reduce((map, seriesClass) => (map[seriesClass.id] = seriesClass.name, map), {});
			this.setState({ classes: classes });

			//* this is stupid but it works for now!

			/*
			if (!this.props.showAllButton && data.length != 0) {
				this.changeClassId(data[0].id);
			}
			*/
		});
	}

	componentWillReceiveProps(nextProps) {

		api.request(`results/SeriesResult?seriesId=${nextProps.seriesId}&classid=${nextProps.classId}`).then(data => {
			this.setState({ results: data });			
		});
	}

	state = {
		results: [],
		races: [],
		classes: {}
	};


	render() {
		return (
			<div>
				<table className="table table-striped table-hover table-condensed header-fixed'">
					<thead>
						<tr>
							<th>Pos</th>
							<th>#</th>
							<th>Name</th>
							<th>Age</th>
							<th>Class</th>
							<th>Total Points</th>
							{this.state.races.map((race) =>
								<th key={race.id}>{race.name}</th>
							)}

						</tr>
					</thead>
					<tbody>
						{this.state.results.map((result) =>
							<tr key={result.competitorId}>
								<td>{result.position}</td>
								<td>{result.competitorNumber}</td>
								<td>{result.competitorName}</td>
								<td>{result.competitorAge}</td>
								<td>{this.state.classes[result.competitorClassId]}</td>
								<td>{result.totalPoints}</td>
								{result.raceResults.map((raceResult,index) =>
									<td key={index}>{(raceResult && !raceResult.dnf) ? raceResult.points : '-'}</td>
								)}
							</tr>
						)}

					</tbody>
				</table>
			</div>
		)
	}
}