import * as axios from 'axios';


class ApiClient {
	
	private _apiRoot : string = '';

	constructor(apiRoot:string){
		this._apiRoot = apiRoot;
	}

	public request(resource:string) {
		console.time('Request:' + resource);

		var promise = axios.default.get(this._apiRoot + resource);
		return promise.then(response => {
			return response.data;
		}).catch(error => {
			alert(error + '\n' + error.response.data);
		});

		//return promise;
	}

	public post(resource: string, data: any, throwOnError = false) {

		console.time('Post:' + resource);
		var promise = axios.default.post(this._apiRoot + resource, data);

		return promise.then(response => {
			return response.data;
		}).catch(error => {
			alert(error + '\n' +  error.response.data);
			if (throwOnError) throw error;
		});
	}

	public put(resource: string, data: any, throwOnError = false) {

		console.time('Put:' + resource);
		var promise = axios.default.put(this._apiRoot + resource, data);

		return promise.then(response => {
			return response.data;
		}).catch(error => {
			console.log(error);
			alert(error + '\n' + error.response.data);
			if (throwOnError) throw error;
		});

		//return promise;
	}

	public delete(resource: string) {

		console.time('Delete:' + resource);
		return axios.default.delete(this._apiRoot + resource).then(response => {
			return response.data;
		}).catch(error=>{
			console.log(error + ' ' + error.response.data);
			alert(error + '\n' + error.response.data);
		})		
	}
}

export default new ApiClient('/api/');
