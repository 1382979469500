import * as React from 'react';
import api from './ApiClient';


interface PointsTableProps {

	eventId: any,
	classId: any,
};

export default class PointsTable extends React.Component<PointsTableProps, any> {

	componentDidMount() {
		/*
		if (this.props.classId != '') {
			api.request(`results/EventResult?eventid=${this.props.eventId}&classid=${this.props.classId}`).then(data => {
				this.setState({ results: data });
				console.log(data);
			});
		}
		*/
	}

	componentWillReceiveProps(nextProps) {

		api.request(`results/EventResult?eventid=${nextProps.eventId}&classid=${nextProps.classId}`).then(data => {
			this.setState({ results: data });
		});


		api.request('eventraces/?eventid=' + this.props.eventId).then(data => {
			this.setState({ races: data });
		});
	}

	state = {
		results: [],
		races:[]
	};


	render() {
		return (
			<div>
				<table className="table table-striped table-hover table-condensed header-fixed'">
					<thead>
						<tr>
							<th>Pos</th>
							<th>Number</th>
							<th>Name</th>
							<th>Age</th>
							<th>Total Points</th>
							{this.state.races.map((race) =>
								<th key={race.id}>{race.name} </th>
							)}

						</tr>
					</thead>
					<tbody>
						{this.state.results.map((result) => 
							<tr key={result.competitorId}>
								<td>{result.position}</td>																
								<td>{result.competitorNumber}</td>
								<td>{result.competitorName}</td>
								<td>{result.competitorAge}</td>
								<td>{result.totalPoints}</td>
								{result.raceResults.map((raceResult) => 
									<td>{(raceResult && !raceResult.dnf)?raceResult.points:'-'}</td>
								)}
							</tr>
						)}
					</tbody>
				</table>
			</div>
		)
	}
}