import * as React from 'react';

import SeriesList from './../Components/SeriesList';
import RaceSummary from './../Components/RaceSummary';


export default class HomePage extends React.Component<any, any> {

	constructor(props: any) {

		super(props);

	}
	
	render() {		
		return (
			<div>
				<h2>Upcoming</h2>
				<RaceSummary/>

				<h2>Series</h2>
				<SeriesList/>
			</div>
		)
	}
}
